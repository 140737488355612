import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

const TopbarProfile = ({
  t,
}) => {
  const [collapse, setCollapse] = useState(false);

  return !isMaintenance && (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={() => setCollapse(!collapse)}>
        {/* <img className="topbar__avatar-img" src={!session.account ? EmptyAva : Ava} alt="Profile" /> */}
        {/* <p className="topbar__avatar-name">Browse</p> */}
        <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/menuIcon.png`} alt="Menu" />
        {/* <DownIcon className="topbar__icon" /> */}
      </button>
      {collapse && <button className="topbar__back" onClick={() => setCollapse(!collapse)} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {!isMaintenance && <TopbarMenuLink title={t('footer.home')} /* icon="home" */ exact path="/home" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.product_download')} /* icon="home" */ exact path="/joysible_download20211216" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.aboutus')} /* icon="text-align-justify" */ exact path="/about-us" onClick={() => setCollapse(!collapse)} />}
          </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarProfile);
